import React from 'react'

const Banner = (props) => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        <h1>
          <span style={{ color: '#ff671f' }}>Krypton Warriors</span> | 16367
        </h1>
      </header>
      <div className="content">
        <p>
          A competitive FTC Team<br /> Located in Montville New Jersey
        </p>
        <ul className="actions">
          <li>
            <a href="#one" className="button next scrolly">
              Let's Go
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
)

export default Banner
